::-webkit-scrollbar {
    width: 5px;
    border-radius: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #666;
}

h3,
h4,
h5 {
    color: #213e99 !important;
    padding-bottom: 10px;
}

p {
    color: #0d183c;
    font-weight: 500;
}

a {
    cursor: pointer;
}

section {
    height: 100vh;
}

Footer {
    height: 60px;
    display: flex;
    align-items: center;
    margin: 15px;
}

Footer > a > img {
    height: 80px;
}

.modalNewContactLabel,
.modalEditContact {
    color: #0d183c99;
}

#password-error-message .rs-form-error-message {
    white-space: pre-wrap;
    width: auto;
    inline-size: max-content;
}

.newFileName {
    margin-bottom: 20px;
}

.newFileName > div,
.newFileName > div > input {
    width: 100%!important;
}

.modalBackdrop {
    background: #0015299e !important;
}

.rs-modal-backdrop.modalBackdrop.rs-anim-fade.rs-anim-in {
    z-index: 1050;
}

.rowDisabled .rs-table-cell .rs-table-cell-wrap {
    color: #b7b7bb;
}

.rs-breadcrumb {
    font-weight: 700 !important;
    font-size: 14px !important;
}

.rs-btn-link:focus,
.rs-btn-link:hover {
    text-decoration: none !important;
}

.rs-input:focus,
.rs-picker-search-input:focus,
.rs-picker-toggle-active {
    outline: 0 !important;
    box-shadow: none !important;
}

.rs-picker-focused {
    box-shadow: none !important;
}

.rs-modal-body {
    padding-bottom: 0!important;
}

.rs-checkbox {
    margin-bottom: 10px;
}

.rs-checkbox-checker > label {
    font-weight: 500 !important;
}

.rs-checkbox.rs-checkbox-checked > .rs-checkbox-checker > label {
    color: #1890ff;
}

.rs-checkbox > .rs-checkbox-checker > label {
    color: #0d183c99;
}

.hidden {
    background-color: red;
}

@media (min-width: 500px) {
    .modal {
        width: 426px !important;
    }
}

@media (min-width: 1200px) {
    .rs-grid-container {
        width: 1150px !important;
    }
}

@media (min-width: 1400px) {
    .rs-grid-container {
        width: 1200px !important;
    }
}

@media (max-width: 767px) {
    .rs-grid-container {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

@media (max-width: 420px) {
    p,
    span,
    button {
        font-size: 12px !important;
    }
}
